import { Box } from '@creditas-ui/react'
import { Divider, Collapse } from 'antd'
import {
  CollapseItem,
  StyledCenter,
  StyledCollapse,
  StyledDivider,
  Item,
  Label,
  Name,
  Value,
} from '../LeadDetails.styles'
import {
  ButtonDocs,
  ContractViewerLabel,
} from '../../Lead/components/PersonItens/PersonItens.styles'
import { useState } from 'react'
import { ModalDocViewer } from '../../../shared/components/ModalDocViewer/ModalDocViewer'

const productMap = {
  RENTALS_OWNER: 'Aluguel em Dia Proprietário',
  RENTALS_TENANT: 'Aluguel em Dia Inquilino',
  RENTALS_TENANT_SPECIAL: 'Aluguel em Dia Especial Inquilino',
}

const incomeMap = {
  MONTHLY_INCOME: 'Renda Informada',
  PRESUMED_MONTHLY_INCOME: 'Renda Presumida',
  CONFIRMED_MONTHLY_INCOME: 'Renda Confirmada',
}

const resultMap = {
  APPROVED: 'Aprovado',
  REJECTED: 'Rejeitado',
}

const { Panel } = Collapse

export const LeadDetailsView = ({ leadDetailsData }) => {
  const [viewContract, setViewContract] = useState(false)
  const visibility = (bool) => setViewContract(bool)

  function callDocData() {
    setViewContract(true)
  }

  const LeadDetails = ({ leads }) => (
    <>
      {viewContract && (
        <ModalDocViewer
          idDoc={leads[0]?.contractIdDoc}
          viewContractBool={visibility}
          title={
            leads[0]?.profileAnalysis[0]?.persons?.find((obj) => {
              return obj.lead
            }).name
          }
        />
      )}
      {!viewContract &&
        leads.map((leadItem) => (
          <>
            <div key={leadItem?.olxId}>
              <Box>
                <br />
                <Item>
                  <Name variant='h5'> Análise: {leadItem?.olxId}</Name>
                </Item>
              </Box>

              <Box>
                <Item>
                  <Label>Status da análise</Label>
                  <Value>{leadItem?.statusDescription}</Value>
                </Item>
                {verifyProfileDescription(leadItem) && (
                  <Item>
                    <Label>Resultado da análise de perfil</Label>
                    <Value>{verifyProfileDescription(leadItem)}</Value>
                  </Item>
                )}
                <Item>
                  <Label>Imobiliária</Label>
                  <Value>{leadItem?.realEstate?.name}</Value>
                </Item>
                <Item>
                  <Label>CNPJ Imobiliária</Label>
                  <Value>
                    {leadItem?.realEstate?.cnpj.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5',
                    )}
                  </Value>
                </Item>
                <Item>
                  <Label>Valor do pacote</Label>
                  <Value>
                    R${' '}
                    {parseFloat(
                      leadItem?.rent?.rentAmount +
                        leadItem?.rent?.condominiumAmount +
                        leadItem?.rent?.iptuAmount,
                    ).toFixed(2)}
                  </Value>
                </Item>
                <Item>
                  <Label>Crédito Limite</Label>
                  <Value>
                    {leadItem?.profileAnalysis.reduce((max, pItem) =>
                      max.version > pItem.version ? max : pItem,
                    ).creditLimit
                      ? 'R$ ' +
                        parseFloat(
                          leadItem?.profileAnalysis.reduce((max, pItem) =>
                            max.version > pItem.version ? max : pItem,
                          ).creditLimit,
                        ).toFixed(2)
                      : 'N/A'}
                  </Value>
                </Item>
                <Item>
                  <Label>Produto</Label>
                  <Value>{productMap[leadItem?.product]}</Value>
                </Item>
                {leadItem?.hasTJPendency != null && (
                  <Item>
                    <Label>Possui pendencia de TJ</Label>
                    <Value>{leadItem?.hasTJPendency ? 'Sim' : 'Não'}</Value>
                  </Item>
                )}
                {leadItem?.contractIdDoc && (
                  <Item>
                    <ButtonDocs>
                      <ContractViewerLabel onClick={() => callDocData(leadItem?.contractIdDoc)}>
                        Visualizar contrato
                      </ContractViewerLabel>
                    </ButtonDocs>
                  </Item>
                )}
              </Box>

              <StyledCollapse>
                <Panel header={'Detalhes'}>
                  <Box>
                    <CollapseItem>
                      <Label>Valor do aluguel</Label>
                      <Value>R$ {parseFloat(leadItem?.rent?.rentAmount).toFixed(2)}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Valor do Condomínio</Label>
                      <Value>R$ {parseFloat(leadItem?.rent?.condominiumAmount).toFixed(2)}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Valor do IPTU</Label>
                      <Value>R$ {parseFloat(leadItem?.rent?.iptuAmount).toFixed(2)}</Value>
                    </CollapseItem>
                    <Divider dashed />
                    <CollapseItem>
                      <Label>Rua</Label>
                      <Value>{leadItem?.rent?.streetAddress}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Bairro</Label>
                      <Value>{leadItem?.rent?.neighborhood}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>Número</Label>
                      <Value>{leadItem?.rent?.streetNumber}</Value>
                    </CollapseItem>
                    {leadItem?.rent?.streetComplement && (
                      <CollapseItem>
                        <Label>Complemento</Label>
                        <Value>{leadItem?.rent?.streetComplement}</Value>
                      </CollapseItem>
                    )}
                    <CollapseItem>
                      <Label>Cidade</Label>
                      <Value>{leadItem?.rent?.city}</Value>
                    </CollapseItem>
                    <CollapseItem>
                      <Label>CEP</Label>
                      <Value>{leadItem?.rent?.zipCode}</Value>
                    </CollapseItem>
                  </Box>
                </Panel>
              </StyledCollapse>

              <br />

              <Box>
                <Item>
                  <Name variant='h5'> Proponentes:</Name>
                </Item>
              </Box>

              {leadItem?.profileAnalysis?.map((leadProfileItem) => (
                <>
                  {leadProfileItem?.persons?.map((personItem) => (
                    <>
                      <Box>
                        <Item>
                          <Label>Nome proponente</Label>
                          <Value>{personItem?.name}</Value>
                        </Item>
                        <Item>
                          <Label>CPF</Label>
                          <Value>{personItem?.cpf}</Value>
                        </Item>
                        {personItem?.contacts?.map((personContactsItem) => (
                          <>
                            <Item>
                              <Label>
                                {personContactsItem?.channel === 'PHONE'
                                  ? 'Telefone' || personContactsItem?.channel === 'EMAIL'
                                  : 'E-mail'}
                              </Label>
                              <Value>{personContactsItem?.code}</Value>
                            </Item>
                          </>
                        ))}
                        {personItem?.incomes?.map((personIncomeItem) => (
                          <>
                            <Item>
                              <Label>{incomeMap[personIncomeItem?.type]}</Label>
                              <Value>R$ {parseFloat(personIncomeItem?.amount).toFixed(2)}</Value>
                            </Item>
                          </>
                        ))}
                        <Item>
                          <Label>Avaliação</Label>
                          <Value>{personItem?.evaluation ?? 'N/A'}</Value>
                        </Item>
                        <Item>
                          <Label>Tipo de avaliação</Label>
                          <Value>{personItem?.evaluationType ?? 'N/A'}</Value>
                        </Item>
                        <Item>
                          <StyledDivider />
                        </Item>
                      </Box>
                    </>
                  ))}
                </>
              ))}
            </div>
          </>
        ))}
    </>
  )

  const LeadDetailsNewFlow = ({ leads }) => (
    <>
      {viewContract && (
        <ModalDocViewer
          idDoc={leads[0]?.contractIdDoc}
          viewContractBool={visibility}
          title={
            leads[0]?.profileAnalysis[0]?.persons?.find((obj) => {
              return obj.lead
            }).name
          }
        />
      )}
      {!viewContract &&
        leads.map((leadItem) => (
          <>
            <div key={leadItem?.olxId || leadItem?.profileAnalysisId}>
              <Box>
                <br />
                <Item>
                  <Name variant='h5'>
                    {' '}
                    Análise: {leadItem?.olxId || leadItem?.profileAnalysisId}
                  </Name>
                </Item>
              </Box>
              <Box>
                {leadItem?.statusDescription ? (
                  <>
                    <Item>
                      <Label>Situação do Lead</Label>
                      <Value>{leadItem?.statusDescription}</Value>
                    </Item>
                  </>
                ) : (
                  <></>
                )}
                <Item>
                  <Label>Imobiliária</Label>
                  <Value>{leadItem?.realEstate?.name}</Value>
                </Item>
                <Item>
                  <Label>CNPJ Imobiliária</Label>
                  <Value>
                    {leadItem?.realEstate?.cnpj.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5',
                    )}
                  </Value>
                </Item>
                <Item>
                  <Label>Crédito Limite</Label>
                  <Value>
                    {verifyPotentialIncome(leadItem).confirmedPotential ??
                      verifyPotentialIncome(leadItem).presumedPotential ??
                      verifyPotentialIncome(leadItem).informedPotential ??
                      'N/A'}
                  </Value>
                </Item>
                {leadItem?.hasTJPendency != null && (
                  <Item>
                    <Label>Possui pendencia de TJ</Label>
                    <Value>{leadItem?.hasTJPendency ? 'Sim' : 'Não'}</Value>
                  </Item>
                )}
                {leadItem?.contractIdDoc && (
                  <Item>
                    <ButtonDocs>
                      <ContractViewerLabel onClick={() => callDocData(leadItem?.contractIdDoc)}>
                        Visualizar contrato
                      </ContractViewerLabel>
                    </ButtonDocs>
                  </Item>
                )}
                <Item>
                  <Divider dashed />
                </Item>
              </Box>

              <Box>
                <Item>
                  <Name variant='h5'> Proponentes:</Name>
                </Item>
              </Box>

              {leadItem?.preProfileAnalysis?.preProfileAnalysisPersons?.map((personItem) => (
                <>
                  <Box>
                    <Item>
                      <Label>Nome proponente</Label>
                      <Value>{personItem?.name}</Value>
                    </Item>
                    <Item>
                      <Label>CPF</Label>
                      <Value>{personItem?.documentNumber}</Value>
                    </Item>
                    <Item>
                      <Label>Telefone</Label>
                      <Value>{personItem?.phone}</Value>
                    </Item>
                    <Item>
                      <Label>Email</Label>
                      <Value>{personItem?.email}</Value>
                    </Item>
                    <Item>
                      <Label>Renda Informada</Label>
                      <Value>R$ {parseFloat(personItem?.informedIncome).toFixed(2)}</Value>
                    </Item>
                    <Item>
                      <Label>Avaliação</Label>
                      <Value>{resultMap[personItem?.result] ?? 'N/A'}</Value>
                    </Item>
                    <Item>
                      <Label>Status do processamento da biomatria</Label>
                      <Value>
                        {(
                          leadItem?.biometries?.find(
                            (biometry) => biometry.documentNumber === personItem?.documentNumber,
                          ) || null
                        )?.statusProcessing ?? 'N/A'}
                      </Value>
                    </Item>
                    <Item>
                      <Label>Status atual biometria</Label>
                      <Value>
                        {(
                          leadItem?.biometries?.find(
                            (biometry) => biometry.documentNumber === personItem?.documentNumber,
                          ) || null
                        )?.currentAutoStatus ?? 'N/A'}
                      </Value>
                    </Item>
                    <Item>
                      <Label>Mensagem da análise de biometria</Label>
                      <Value>
                        {(
                          leadItem?.biometries?.find(
                            (biometry) => biometry.documentNumber === personItem?.documentNumber,
                          ) || null
                        )?.currentAutoStatusDesc ?? 'N/A'}
                      </Value>
                    </Item>
                    <Item>
                      <StyledDivider />
                    </Item>
                  </Box>
                </>
              ))}

              {leadItem?.persons?.map((personItem) => (
                <>
                  <Box>
                    <Item>
                      <Label>Nome proponente</Label>
                      <Value>{personItem?.name}</Value>
                    </Item>
                    <Item>
                      <Label>CPF</Label>
                      <Value>{personItem?.documentNumber}</Value>
                    </Item>
                    <Item>
                      <Label>Telefone</Label>
                      <Value>{personItem?.phone}</Value>
                    </Item>
                    <Item>
                      <Label>Email</Label>
                      <Value>{personItem?.email}</Value>
                    </Item>
                    <Item>
                      <Label>Renda Informada</Label>
                      <Value>R$ {parseFloat(personItem?.informedIncome).toFixed(2)}</Value>
                    </Item>
                    <Item>
                      <Label>Avaliação</Label>
                      <Value>{resultMap[personItem?.result] ?? 'N/A'}</Value>
                    </Item>
                    <Item>
                      <Label>Status do processamento da biometria</Label>
                      <Value>
                        {(
                          personItem?.biometries?.find(
                            (biometry) => biometry.documentNumber === personItem?.documentNumber,
                          ) || null
                        )?.statusProcessing ?? 'N/A'}
                      </Value>
                    </Item>
                    <Item>
                      <Label>Status atual biometria</Label>
                      <Value>
                        {(
                          personItem?.biometries?.find(
                            (biometry) => biometry.documentNumber === personItem?.documentNumber,
                          ) || null
                        )?.currentAutoStatus ?? 'N/A'}
                      </Value>
                    </Item>
                    <Item>
                      <Label>Mensagem da análise de biometria</Label>
                      <Value>
                        {(
                          personItem?.biometries?.find(
                            (biometry) => biometry.documentNumber === personItem?.documentNumber,
                          ) || null
                        )?.currentAutoStatusDesc ?? 'N/A'}
                      </Value>
                    </Item>
                    <Item>
                      <StyledDivider />
                    </Item>
                  </Box>
                </>
              ))}
            </div>
          </>
        ))}
    </>
  )

  if (leadDetailsData.length === 0) {
    return (
      <StyledCenter>
        <Name variant='h5'>A sua pesquisa não encontrou registros correspondentes.</Name>
      </StyledCenter>
    )
  }

  const verifyProfileDescription = (profileAnalysisDetail) => {
    return (
      profileAnalysisDetail?.profileAnalysis[profileAnalysisDetail?.profileAnalysis.length - 1]
        ?.profileResultDetailedDescription ?? null
    )
  }

  const formatIncome = (amount) => {
    return amount ? `R$ ${parseFloat(amount).toFixed(2)}` : undefined
  }

  const verifyPotentialIncome = (lead) => {
    const incomeMap = {}
    const analysis = lead.preProfileAnalysis || lead

    incomeMap.confirmedPotential = formatIncome(
      analysis.confirmedPotential || analysis.confirmedPotencial,
    )
    incomeMap.presumedPotential = formatIncome(
      analysis.presumedPotential || analysis.presumedPotencial,
    )
    incomeMap.informedPotential = formatIncome(
      analysis.informedPotential || analysis.informedPotencial,
    )

    return incomeMap
  }

  return (
    <>
      <LeadDetails leads={leadDetailsData?.oldFlow} />

      <LeadDetailsNewFlow leads={leadDetailsData?.newFlow} />
    </>
  )
}
