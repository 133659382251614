import { Box, ButtonPrimaryTextOnly } from '@creditas-ui/react'
import { Divider } from 'antd'
import {
  StyledCenter,
  StyledDivider,
  Item,
  Label,
  Name,
  Value,
  StyledBox,
} from '../LeadsList.styles'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export const LeadsDetailsList = ({ leadsDetailData, hasSearch }) => {
  let navigate = useNavigate()

  const navigateToLeadByOlxId = (olxId) => {
    navigate('/lead/' + olxId + '/details')
  }

  const resultMap = {
    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',
  }

  const LeadDetails = ({ lead }) => (
    <>
      <div key={lead?.olxId}>
        <Box>
          <br />
          <Item>
            <Name variant='h5'>Lead: {lead?.olxId}</Name>
          </Item>
          <br />
          <Item>
            <Name variant='h6'>Resultado da análise: {lead?.statusDescription}</Name>
          </Item>
          <Item>
            <Name variant='h6'>
              Data de criação: {moment(lead?.createDate, 'DD/MM/YYYY HH:mm').format('L LT')}
            </Name>
          </Item>
          <br />
        </Box>

        {lead?.profileAnalysis?.map((profileItem) =>
          profileItem?.persons?.map((personItem) => (
            <Box key={personItem?.cpf}>
              <Item>
                <Label>Nome proponente:</Label>
                <Value>{personItem?.name}</Value>
              </Item>
              <Item>
                <Label>CPF</Label>
                <Value>{personItem?.cpf}</Value>
              </Item>
              <Item>
                <Label>Avaliação</Label>
                <Value>{personItem?.evaluation ?? 'N/A'}</Value>
              </Item>
              <Item>
                <Divider dashed />
              </Item>
            </Box>
          )),
        )}

        <StyledBox>
          <ButtonPrimaryTextOnly
            data-testid='btn-new-proposal'
            variant='primary'
            onClick={() => navigateToLeadByOlxId(lead?.olxId)}
          >
            Detalhes do Lead
          </ButtonPrimaryTextOnly>
        </StyledBox>

        <Item>
          <StyledDivider />
        </Item>
      </div>
    </>
  )

  const LeadDetailsNewFlow = ({ lead }) => (
    <>
      <div key={lead?.olxId || lead?.profileAnalysisId}>
        <Box>
          <br />
          <Item>
            <Name variant='h5'> Análise de Perfil: {lead?.olxId || lead?.profileAnalysisId}</Name>
          </Item>
          <br />
          {lead?.olxId ? (
            <>
              <Item>
                <Name variant='h6'>Resultado da análise: {lead?.statusDescription}</Name>
              </Item>
            </>
          ) : (
            <></>
          )}
          <Item>
            <Name variant='h6'>
              Data de criação: {moment(lead?.createDate, 'DD/MM/YYYY HH:mm').format('L LT')}
            </Name>
          </Item>
          <br />
        </Box>

        {lead?.persons?.map((person) => (
          <>
            <Box>
              <Item>
                <Label>Nome proponente:</Label>
                <Value>{person?.name}</Value>
              </Item>
              <Item>
                <Label>CPF</Label>
                <Value>{person?.documentNumber}</Value>
              </Item>
              <Item>
                <Label>Avaliação</Label>
                <Value>{resultMap[person?.result] ?? 'N/A'}</Value>
              </Item>
              <Item>
                <Divider dashed />
              </Item>
            </Box>
          </>
        ))}

        {lead?.preProfileAnalysis?.preProfileAnalysisPersons?.map((person) => (
          <>
            <Box>
              <Item>
                <Label>Nome proponente:</Label>
                <Value>{person?.name}</Value>
              </Item>
              <Item>
                <Label>CPF</Label>
                <Value>{person?.documentNumber}</Value>
              </Item>
              <Item>
                <Label>Avaliação</Label>
                <Value>{resultMap[person?.result] ?? 'N/A'}</Value>
              </Item>
              <Item>
                <Divider dashed />
              </Item>
            </Box>
          </>
        ))}
      </div>

      <StyledBox>
        <ButtonPrimaryTextOnly
          data-testid='btn-new-proposal'
          variant='primary'
          onClick={() => navigateToLeadByOlxId(lead?.olxId || lead?.profileAnalysisId)}
        >
          Detalhes do Lead
        </ButtonPrimaryTextOnly>
      </StyledBox>

      <Item>
        <StyledDivider />
      </Item>
    </>
  )

  const NoResults = () => (
    <StyledCenter>
      <Name variant='h5'>A sua pesquisa não encontrou registros correspondentes.</Name>
    </StyledCenter>
  )

  if (
    leadsDetailData?.oldFlow?.length === 0 &&
    leadsDetailData?.newFlow?.length === 0 &&
    hasSearch
  ) {
    return <NoResults />
  }

  return (
    <>
      {leadsDetailData?.oldFlow?.map((item) => (
        <>
          {item?.length > 0 ? (
            <>
              {item?.map((leadDetailsItem) => (
                <LeadDetails key={leadDetailsItem?.olxId} lead={leadDetailsItem} />
              ))}
            </>
          ) : (
            <LeadDetails lead={item} />
          )}
        </>
      ))}

      {leadsDetailData?.newFlow?.map((item) => (
        <>
          {item?.length > 0 ? (
            <>
              {item?.map((leadDetailsItem) => (
                <LeadDetailsNewFlow
                  key={leadDetailsItem?.olxId || leadDetailsItem?.profileAnalysisId}
                  lead={leadDetailsItem}
                />
              ))}
            </>
          ) : (
            <LeadDetailsNewFlow lead={item} />
          )}
        </>
      ))}
    </>
  )
}
