import { destroyCredentials } from '../infrastructure/authentication/auths'
import axios from 'axios'
import {
  configContractAnalysis,
  configContractDetails,
  configGetInfoDoc,
  configGetInfoDocBlob,
  configLeadByLeadId,
  configLeadByRenPaId,
  configLeadHistory,
  configLeads,
  configLeadsDetail,
  configLeadsListByDocument,
  configLeadByDocumentNumber,
  configManualAnalysis,
  configPendingContracts,
} from './config'
import { Api, App, AuthServices, DocumentsApi } from '../services/api'

const consultantName = localStorage.getItem('username') || null

const logout = () => {
  destroyCredentials()
  localStorage.clear()
  window.location.assign('/')
}

const getLeadsList = async () => {
  return axios.get(`${Api}/olx/income/listmanualanalysis`, configLeads())
}

const getLeadDetails = async (id) => {
  return axios.get(
    `${Api}/olx/income/${id}?consultantName=${JSON.parse(consultantName)}`,
    configLeadsDetail(),
  )
}

const getLeadHistory = async (id) => {
  return axios.get(
    `${Api}/business/leads/${id}/histories?statusGroup=GREY_ZONE_INCOME`,
    configLeadHistory(),
  )
}

const getLeadsListByDocument = async (document) => {
  return axios.get(
    `${Api}/business/leads?typeSearch=DOCUMENT&document=${document
      .replace(/^[, . -]+|[, . -]+$|[, . -]+/g, '')
      .trim()}`,
    configLeadsListByDocument(),
  )
}

const getLeadsListByOlxId = async (OlxId) => {
  return axios.get(
    `${Api}/business/leads?typeSearch=ID_OLX&idOlx=${OlxId?.trim()}`,
    configLeadByLeadId(),
  )
}

const getLeadsListByRenPaId = async (renPaId) => {
  return axios.get(
    `${Api}/profile-analysis/details?profileAnalysisId=${renPaId}`,
    configLeadByRenPaId(),
  )
}

const getLeadsListByDocumentNumber = async (documentNumber) => {
  return axios.get(
    `${Api}/profile-analysis/details?documentNumber=${documentNumber}`,
    configLeadByDocumentNumber(),
  )
}

const postManualAnalysis = async (requestData) => {
  return axios.post(`${Api}/olx/income`, requestData, configManualAnalysis())
}

const getTokenAuth = (code) => {
  return fetch(
    `${AuthServices}/oidc/oauth/token?grant_type=authorization_code&code=${code}&redirect_uri=${App}/login`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(process.env.REACT_APP_CLIENT_ID_OIDC)}`,
      },
    },
  )
}

const getDocData = async (docId) => {
  return axios.get(`${DocumentsApi}/${docId}`, configGetInfoDoc())
}

const getDocBlob = async (docId) => {
  return axios.get(`${DocumentsApi}/${docId}`, configGetInfoDocBlob())
}

const getPendingContracts = async (status) => {
  return axios.get(
    `${Api}/contract?product=RENTALS_TENANT&contractStatus=${status}`,
    configPendingContracts,
  )
}

const getContractDetails = async (olxId) => {
  return axios.get(`${Api}/contract/${olxId}`, configContractDetails())
}

const postContractAnalysis = async (idOlx, requestData) => {
  return axios.post(`${Api}/contract/${idOlx}/result`, requestData, configContractAnalysis())
}

export {
  logout,
  getLeadsList,
  getLeadDetails,
  getLeadHistory,
  getLeadsListByDocument,
  getLeadsListByDocumentNumber,
  getLeadsListByOlxId,
  getLeadsListByRenPaId,
  postManualAnalysis,
  getDocData,
  getDocBlob,
  getPendingContracts,
  getContractDetails,
  postContractAnalysis,
  getTokenAuth,
}
