import { Form } from 'antd'

import {
  Button,
  Container,
  ContainerArea,
  ContainerLogo,
  Div,
  ImageCreditas,
  Logo,
} from './login.styles'

import logo from '../../assets/img/logo-creditas.svg'

import './signin.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { storeCredentials } from '../../infrastructure/authentication'
import { getTokenAuth } from '../../api/api'

export const Login = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const tokenLocalStorage = localStorage.getItem('home-solutions-frontend')
  const [token, setToken] = useState(null)
  if (token) {
    const tokenUser = jwtDecode(token)
    storeCredentials(token)
    localStorage.setItem('username', JSON.stringify(tokenUser.username))
  }

  useEffect(() => {
    let code = searchParams.get('code')

    if (code != null) {
      getTokenAuth(code)
        .then((response) => response.json())
        .then((responseAuth) => {
          let currentToken = responseAuth?.access_token
          if (currentToken) {
            localStorage.setItem('home-solutions-frontend', currentToken)
            setToken(currentToken)
            const tokenUser = jwtDecode(currentToken)
            storeCredentials(currentToken)
            localStorage.setItem('username', JSON.stringify(tokenUser.username))
            navigate('/dashboard')
          }
        })
        .catch((error) => {
          console.error('Erro ao chamar o primeiro endpoint:', error)
        })
    }
  })

  useEffect(() => {
    const token = localStorage.getItem('home-solutions-frontend')

    if (token != null) {
      setToken(token)
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }, [navigate, tokenLocalStorage, setToken])

  async function signinRedirect() {
    window.location.href = `${process.env.REACT_APP_AUTH_SERVICES}/oidc/oauth/authorize?client_id=${
      process.env.REACT_APP_CLIENT_ID_OIDC
    }&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_HOME_SOLUTIONS_FRONTEND + '/login',
    )}&response_type=code&scope=${encodeURIComponent(
      'openid email offline_access',
    )}&response_mode=query`
  }

  return (
    <Container>
      <ContainerArea>
        <ContainerLogo>
          <Logo src={logo} alt='Logo Sistema' />
        </ContainerLogo>

        <Form>
          <Div>
            <Button className='ui button nowrap' onClick={() => signinRedirect()}>
              <ImageCreditas alt='Login com Creditas' src='/images/logo-icon-white.svg' />
              Login com Creditas
            </Button>
          </Div>
        </Form>
      </ContainerArea>
    </Container>
  )
}
