function tokenLocal() {
  return localStorage.getItem('home-solutions-frontend') || null
}

function configLeads() {
  return {
    headers: {
      Accept: 'application/vnd.creditas.v1+json',
      Authorization: `Bearer ${tokenLocal()}`,
    },
  }
}

function configLeadsDetail() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
    },
  }
}

function configLeadHistory() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
    },
  }
}
function configLeadByLeadId() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
    },
  }
}

function configLeadByRenPaId() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
    },
  }
}
function configLeadByDocumentNumber() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
    },
  }
}

function configManualAnalysis() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      'Content-Type': 'application/json',
    },
  }
}

function configLeadsListByDocument() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
    },
  }
}

function configGetInfoDoc() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
      'Content-Type': 'application/json',
    },
    data: {},
  }
}

function configGetInfoDocBlob() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
    data: {},
  }
}

function configPendingContracts() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
    },
  }
}

function configContractDetails() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      Accept: 'application/vnd.creditas.v1+json',
    },
  }
}

function configContractAnalysis() {
  return {
    headers: {
      Authorization: `Bearer ${tokenLocal()}`,
      'Content-Type': 'application/json',
    },
  }
}

export {
  configLeads,
  configLeadsDetail,
  configLeadHistory,
  configLeadByLeadId,
  configLeadByRenPaId,
  configManualAnalysis,
  configLeadsListByDocument,
  configLeadByDocumentNumber,
  configGetInfoDoc,
  configGetInfoDocBlob,
  configPendingContracts,
  configContractDetails,
  configContractAnalysis,
}
